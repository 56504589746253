:root {
    --text-color: #333;
    --text-second-color: #666;
    --text-third-color: #999;
    --border-color: #e8e8e8e8;
    --block-color: #fff;
    /* // bg color */
    --body-bg-color: #f3f3f3;
}

$block-bg: #FFFFFF;
$radius: 4px;

// color
$border-color: #e8e8e8;
// border
$border: 1px solid $border-color;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;

$paddding-4: 4px;
$paddding-8: 8px;
$paddding-12: 12px;
$paddding-16: 16px;
$paddding-23: 32px;

@mixin page($padding: 24) {
    background: $block-bg;
    padding: $padding;
}

@mixin blank_page {
    background: $block-bg;
    padding: 16px;
    border-radius: $radius;
}

@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin line($line) {
    @if $line>1 {
        @include multi-line($line);
    }

    @else {

        @include single-line;
    }

}

// 
$somo-prefix: "somo";
.rc-tooltip {
  opacity: unset;
}
.select-table {
  position: relative;

  &-body {
    // position: fixed;
    // z-index: 9999;
    min-width: 400px;
    max-width: 600px;
    background-color: #f0f2f5;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

    &-list {
      // min-height: 200px;
      max-height: 100%;
      padding: 16px 18px;
      overflow-x: auto;
      position: relative;

    }
  }

  &-row {
    cursor: default;
  }

  &-row_selected {
    color: #1890ff;
    background-color: #e6f7ff;
  }

  .mutil-select-input {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 3px 4px;
    height: 30px;

    &_disbaled {
      pointer-events: none;
      cursor: not-allowed;
    }

    &_focus {
      border: none;
      width: 2px;
      padding: 0;
      outline: none;
      box-shadow: none;
    }
  }

  &-btn {
    cursor: pointer;

    &-disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &-actions {
    &-add {
      position: absolute;
      right: 4px;
      bottom: 0;
    }
  }
}

.rc-tooltip-inner {
  padding: 0
}
.virtual-table-cell-content {
  @include single-line;
  padding: 8px;
}
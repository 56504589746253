:root {
    --text-color: #333;
    --text-second-color: #666;
    --text-third-color: #999;
    --border-color: #e8e8e8e8;
    --block-color: #fff;
    /* // bg color */
    --body-bg-color: #f3f3f3;
}

$block-bg: #FFFFFF;
$radius: 4px;

// color
$border-color: #e8e8e8;
// border
$border: 1px solid $border-color;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;

$paddding-4: 4px;
$paddding-8: 8px;
$paddding-12: 12px;
$paddding-16: 16px;
$paddding-23: 32px;

@mixin page($padding: 24) {
    background: $block-bg;
    padding: $padding;
}

@mixin blank_page {
    background: $block-bg;
    padding: 16px;
    border-radius: $radius;
}

@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin line($line) {
    @if $line>1 {
        @include multi-line($line);
    }

    @else {

        @include single-line;
    }

}

// 
$somo-prefix: "somo";
// https://github.com/imageslr/mweb-themes/blob/master/src/themes/mweb-vue.scss

.markdown-viewer {
  color: #404040;
  font-style: "Frutiger", Helvetica, sans-serif;
  font-weight: 400;

  .markdown-body {
    :root {
      --side-bar-bg-color: #fff;
      --control-text-color: #777;
    }

    /* MWeb：增加 padding */

    .task-list-item {
      list-style-type: none;
      text-indent: -1.5em; // 这个数值刚好令 checkbox 与上一行第一个字对齐

      &:before {
        content: "";
        display: static;
        margin-right: 0px;
      }

      > input[type="checkbox"] {
        text-indent: -1.7em;
      }
    }

    /* MWeb：代码块高亮 */
    code,
    tt,
    pre {
      border-radius: 2px;
      font-family: Roboto Mono, Source Sans Pro, Monaco, courier, monospace !important;
      font-size: 0.92rem;
      color: #e96900;
      overflow-x: auto;
      background-color: #f8f8f8;
    }

    code {
      margin: 0 2px;
      padding: 2px 4px;
    }

    pre code {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
    }

    pre {
      padding: 0.7em 1em;
    }

    // /* cyrillic-ext */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhGq3-cXbKDO1w.woff2") format("woff2");
    //     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    //         U+FE2E-FE2F;
    // }

    // /* cyrillic */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhPq3-cXbKDO1w.woff2") format("woff2");
    //     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    // }

    // /* greek-ext */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhHq3-cXbKDO1w.woff2") format("woff2");
    //     unicode-range: U+1F00-1FFF;
    // }

    // /* greek */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhIq3-cXbKDO1w.woff2") format("woff2");
    //     unicode-range: U+0370-03FF;
    // }

    // /* vietnamese */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhEq3-cXbKDO1w.woff2") format("woff2");
    //     unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
    // }

    // /* latin-ext */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhFq3-cXbKDO1w.woff2") format("woff2");
    //     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    //         U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    // }

    // /* latin */
    // @font-face {
    //     font-family: "Roboto Mono";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Roboto Mono"), local("RobotoMono-Regular"),
    //         url("vue/L0x5DF4xlVMF-BfR8bXMIjhLq3-cXbKD.woff2") format("woff2");
    //     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    //         U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    //         U+FEFF, U+FFFD;
    // }

    // /* cyrillic-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmhduz8A.woff2") format("woff2");
    //     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    //         U+FE2E-FE2F;
    // }

    // /* cyrillic */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwkxduz8A.woff2") format("woff2");
    //     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    // }

    // /* greek-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmxduz8A.woff2") format("woff2");
    //     unicode-range: U+1F00-1FFF;
    // }

    // /* greek */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlBduz8A.woff2") format("woff2");
    //     unicode-range: U+0370-03FF;
    // }

    // /* vietnamese */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmBduz8A.woff2") format("woff2");
    //     unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
    // }

    // /* latin-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmRduz8A.woff2") format("woff2");
    //     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    //         U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    // }

    // /* latin */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 300;
    //     src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu.woff2") format("woff2");
    //     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    //         U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    //         U+FEFF, U+FFFD;
    // }

    // /* cyrillic-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2") format("woff2");
    //     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    //         U+FE2E-FE2F;
    // }

    // /* cyrillic */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2") format("woff2");
    //     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    // }

    // /* greek-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2") format("woff2");
    //     unicode-range: U+1F00-1FFF;
    // }

    // /* greek */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2") format("woff2");
    //     unicode-range: U+0370-03FF;
    // }

    // /* vietnamese */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2") format("woff2");
    //     unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
    // }

    // /* latin-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2") format("woff2");
    //     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    //         U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    // }

    // /* latin */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 400;
    //     src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    //         url("vue/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2") format("woff2");
    //     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    //         U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    //         U+FEFF, U+FFFD;
    // }

    // /* cyrillic-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2") format("woff2");
    //     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    //         U+FE2E-FE2F;
    // }

    // /* cyrillic */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2") format("woff2");
    //     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    // }

    // /* greek-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2") format("woff2");
    //     unicode-range: U+1F00-1FFF;
    // }

    // /* greek */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2") format("woff2");
    //     unicode-range: U+0370-03FF;
    // }

    // /* vietnamese */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2") format("woff2");
    //     unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
    // }

    // /* latin-ext */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2") format("woff2");
    //     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    //         U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    // }

    // /* latin */
    // @font-face {
    //     font-family: "Source Sans Pro";
    //     font-style: normal;
    //     font-weight: 600;
    //     src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    //         url("vue/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2") format("woff2");
    //     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    //         U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    //         U+FEFF, U+FFFD;
    // }

    // html {
    //     font-size: 16px;
    // }

    // body {
    //     font-family: Source Sans Pro, Helvetica Neue, Arial, sans-serif !important;
    //     color: #34495e;
    //     -webkit-font-smoothing: antialiased;
    //     line-height: 1.6rem;
    //     letter-spacing: 0;
    //     margin: 0;
    //     overflow-x: hidden;
    // }

    // body>*:first-child {
    //     margin-top: 0 !important;
    // }

    // body>*:last-child {
    //     margin-bottom: 0 !important;
    // }

    img {
      max-width: 100%;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    a {
      color: #42b983;
      font-weight: 600;
      padding: 0 2px;
      text-decoration: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: bold;
      line-height: 1.4;
      cursor: text;
    }

    h1:hover a.anchor,
    h2:hover a.anchor,
    h3:hover a.anchor,
    h4:hover a.anchor,
    h5:hover a.anchor,
    h6:hover a.anchor {
      text-decoration: none;
    }

    h1 tt,
    h1 code {
      font-size: inherit !important;
    }

    h2 tt,
    h2 code {
      font-size: inherit !important;
    }

    h3 tt,
    h3 code {
      font-size: inherit !important;
    }

    h4 tt,
    h4 code {
      font-size: inherit !important;
    }

    h5 tt,
    h5 code {
      font-size: inherit !important;
    }

    h6 tt,
    h6 code {
      font-size: inherit !important;
    }

    h2 a,
    h3 a {
      color: #34495e;
    }

    h1 {
      padding-bottom: 0.4rem;
      font-size: 2.2rem;
      line-height: 1.3;
    }

    h2 {
      font-size: 1.75rem;
      line-height: 1.225;
      margin: 35px 0 15px;
      padding-bottom: 0.5em;
      // border-bottom: 1px solid #ddd;
    }

    h3 {
      font-size: 1.4rem;
      line-height: 1.43;
      margin: 20px 0 7px;
    }

    h4 {
      font-size: 1.2rem;
    }

    h5 {
      font-size: 1rem;
    }

    h6 {
      font-size: 1rem;
      color: #777;
    }

    p,
    blockquote,
    ul,
    ol,
    dl,
    table {
      margin: 0.8em 0;
    }

    li > ol,
    li > ul {
      margin: 0 0;
    }

    hr {
      height: 2px;
      padding: 0;
      margin: 16px 0;
      background-color: #e7e7e7;
      border: 0 none;
      overflow: hidden;
      box-sizing: content-box;
    }

    body > h2:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    body > h1:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    body > h1:first-child + h2 {
      margin-top: 0;
      padding-top: 0;
    }

    body > h3:first-child,
    body > h4:first-child,
    body > h5:first-child,
    body > h6:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    a:first-child h1,
    a:first-child h2,
    a:first-child h3,
    a:first-child h4,
    a:first-child h5,
    a:first-child h6 {
      margin-top: 0;
      padding-top: 0;
    }

    h1 p,
    h2 p,
    h3 p,
    h4 p,
    h5 p,
    h6 p {
      margin-top: 0;
    }

    li p.first {
      display: inline-block;
    }

    ul,
    ol {
      padding-left: 30px;
    }

    ul:first-child,
    ol:first-child {
      margin-top: 0;
    }

    ul:last-child,
    ol:last-child {
      margin-bottom: 0;
    }

    blockquote {
      border-left: 4px solid #42b983;
      padding: 10px 15px;
      color: #777;
      background-color: rgba(66, 185, 131, 0.1);
    }

    table {
      padding: 0;
      word-break: initial;
      border-collapse: collapse;
    }

    table tr {
      border-top: 1px solid #dfe2e5;
      margin: 0;
      padding: 0;
    }

    table tr:nth-child(2n),
    thead {
      background-color: #fafafa;
    }

    table tr th {
      font-weight: bold;
      border: 1px solid #dfe2e5;
      border-bottom: 0;
      text-align: left;
      margin: 0;
      padding: 6px 13px;
    }

    table tr td {
      border: 1px solid #dfe2e5;
      text-align: left;
      margin: 0;
      padding: 6px 13px;
    }

    table tr th:first-child,
    table tr td:first-child {
      margin-top: 0;
    }

    table tr th:last-child,
    table tr td:last-child {
      margin-bottom: 0;
    }

    .cm-s-inner .cm-link,
    .cm-s-inner.cm-link {
      color: #22a2c9;
    }

    .cm-s-inner .cm-string {
      color: #22a2c9;
    }

    .md-task-list-item > input {
      margin-left: -1.3em;
    }

    @media print {
      html {
        font-size: 13px;
      }

      table,
      pre {
        page-break-inside: avoid;
      }

      pre {
        word-wrap: break-word;
      }
    }

    .md-fences {
      background-color: #f8f8f8;
    }

    .mathjax-block > .code-tooltip {
      bottom: 0.375rem;
    }

    .md-image > .md-meta {
      border-radius: 3px;
      font-family: Consolas, "Liberation Mono", Courier, monospace;
      padding: 2px 0 0 4px;
      font-size: 0.9em;
      color: inherit;
    }

    .md-tag {
      color: inherit;
    }

    .md-toc {
      margin-top: 20px;
      padding-bottom: 20px;
    }

    .sidebar-tabs {
      border-bottom: none;
    }

    #typora-quick-open {
      border: 1px solid #ddd;
      background-color: #f8f8f8;
    }

    #typora-quick-open-item {
      background-color: #fafafa;
      border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
      border-style: solid;
      border-width: 1px;
    }

    #md-notification:before {
      top: 10px;
    }

    /** focus mode */

    .on-focus-mode blockquote {
      border-left-color: rgba(85, 85, 85, 0.12);
    }

    header,
    .context-menu,
    .megamenu-content,
    footer {
      font-family: "Segoe UI", "Arial", sans-serif;
    }

    .file-node-content:hover .file-node-icon,
    .file-node-content:hover .file-node-open-state {
      visibility: visible;
    }

    .mac-seamless-mode #typora-sidebar {
      background-color: var(--side-bar-bg-color);
    }

    .md-lang {
      color: #b4654d;
    }

    .html-for-mac .context-menu {
      --item-hover-bg-color: #e6f0fe;
    }

    /* prismJS 代码高亮样式 */
    code[class*="language-"],
    pre[class*="language-"] {
      font-family: Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter",
        "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier,
        monospace;
      font-size: 14px;
      line-height: 1.375;
      direction: ltr;
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;
      -webkit-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
      background: #fafbf9;
      color: #4299d7;
    }

    pre[class*="language-"] {
      padding: 1em;
      margin: 0.5em 0;
      overflow: auto;
    }

    :not(pre) > code[class*="language-"] {
      padding: 0.1em;
      border-radius: 0.3em;
    }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: #bdc3b7;
    }

    .token.punctuation {
      color: #bdc3b7;
    }

    .token.namespace {
      opacity: 0.7;
    }

    .token.tag,
    .token.operator,
    .token.number {
      color: #1b6498;
    }

    .token.property,
    .token.function {
      color: #66a329;
    }

    .token.tag-id,
    .token.selector,
    .token.atrule-id {
      color: #4d8217;
    }

    code.language-javascript,
    .token.attr-name {
      color: #66a329;
    }

    code.language-css,
    code.language-scss,
    .token.boolean,
    .token.string,
    .token.entity,
    .token.url,
    .language-css .token.string,
    .language-scss .token.string,
    .style .token.string,
    .token.attr-value,
    .token.keyword,
    .token.control,
    .token.directive,
    .token.unit,
    .token.statement,
    .token.regex,
    .token.atrule {
      color: #277fbe;
    }

    .token.placeholder,
    .token.variable {
      color: #8dcefc;
    }

    .token.deleted {
      text-decoration: line-through;
    }

    .token.inserted {
      border-bottom: 1px dotted #4d8217;
      text-decoration: none;
    }

    .token.italic {
      font-style: italic;
    }

    .token.important,
    .token.bold {
      font-weight: bold;
    }

    .token.important {
      color: #66a329;
    }

    .token.entity {
      cursor: help;
    }

    pre > code.highlight {
      outline: 0.4em solid #66a329;
      outline-offset: 0.4em;
    }

    .line-numbers .line-numbers-rows {
      border-right-color: #f3f4f1;
    }

    .line-numbers-rows > span:before {
      color: #d1d6cd;
    }

    .line-highlight {
      background: rgba(77, 130, 23, 0.2);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(70%, rgba(77, 130, 23, 0.2)),
        to(rgba(77, 130, 23, 0))
      );
      background: linear-gradient(to right, rgba(77, 130, 23, 0.2) 70%, rgba(77, 130, 23, 0));
    }
  }
}
